import React from "react"
import "../../estilos.sass"
import { List } from '../list'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import "rbx/index.css";
import { Icon, Card, Column, Image, Hero, Container, Title, Content, Media, Button } from "rbx";

function Portfolio() {
	return(
		<>
     <Hero size="fullheight-with-navbar">
        <Hero.Body>
          <Container fluid textAlign="centered">
            <Title className="title-head">progetti</Title>
            <br />


    {List.map(list => (

    <Column.Group>
                <Column size={10} offset={2}>
                    <Card id={list.id}>
                        <Card.Image>
                            <Image.Container size="3by2">
                            <Image src={list.image} />
                            </Image.Container>
                        </Card.Image>
                        <Card.Content>
                        <Media>

							<Media.Item as="figure" align="left">
								<Image.Container as="p" size={64}>
								<Image rounded
									alt="64x64"
									src={list.avatar}
								/>
								</Image.Container>
							</Media.Item>
							
                            <Media.Item>
                                <Title className="title-card"  as="p" size={4}>
                                {list.name}
                                </Title>
								<Title className="subtitle-card" as="p" subtitle size={6}>
								{list.fecha}
								</Title>
                            </Media.Item>
                            </Media>
                            <Content>
                                <p className="text-card">{list.description}</p> 
                                <Button.Group> 

                                <Button rounded as="a" className="button-card" target="_blank" href={list.url}>
                                     <Icon className="icon">
                                        <FontAwesomeIcon icon={faLink} />
                                     </Icon>
                                     Link
                                </Button>
                                <Button rounded as="a" className="button-card" target="_blank" href={list.github}>
                                     <Icon className="icon">
                                        <FontAwesomeIcon icon={faGithub} />
                                     </Icon>
                                    Github
                                </Button>
                                </Button.Group>
                            </Content>
                        </Card.Content>
                    </Card>
                </Column>
            </Column.Group>

  ))}
                    

                </Container>

        </Hero.Body>
      </Hero>
  </>
	)
}



export default Portfolio;




/* const Portfolio = () => (
    
const [isClick, setClick] = useState(false)
    
  <>
     <Hero size="fullheight-with-navbar">
        <Hero.Body>
          <Container fluid textAlign="centered">
            <Title className="title-head">progetti</Title>
            <br />


    {List.map(list => (

    <Column.Group>
                <Column size={10} offset={2}>
                    <Card id={list.id}>
                        <Card.Image>
                            <Image.Container size="3by2">
                            <Image src={list.image} />
                            </Image.Container>
                        </Card.Image>
                        <Card.Content>
                        <Media>

							<Media.Item as="figure" align="left">
								<Image.Container as="p" size={64}>
								<Image rounded
									alt="64x64"
									src={list.avatar}
								/>
								</Image.Container>
							</Media.Item>
							
                            <Media.Item>
                                <Title className="title-card"  as="p" size={4}>
                                {list.name}
                                </Title>
								<Title className="subtitle-card" as="p" subtitle size={6}>
								{list.fecha}
								</Title>
                            </Media.Item>
                            </Media>
                            <Content>
                                <p className="text-card">{list.description}</p> 
                                <Button.Group>
								
								

                                <Button as="a" className="button-card" target="_blank" href="https://messaggi.netlify.app/">
                                    Link
                                     <Icon className="icon">
                                        <FontAwesomeIcon icon={faLink} />
                                     </Icon>
                                </Button>
                                <Button as="a" className="button-card" target="_blank" href="https://github.com/estebansanmartin/messenger">
                                    Github
                                     <Icon className="icon">
                                        <FontAwesomeIcon icon={faGithub} />
                                     </Icon>
                                </Button>
                                </Button.Group>
                            </Content>
                        </Card.Content>
                    </Card>
                </Column>
            </Column.Group>

  ))}
                    

                </Container>

        </Hero.Body>
      </Hero>
  </>
)

export default Portfolio */

/*<body class="demo-1">
	<main>
		<nav class="menu">
			<div class="menu__item">
				<Generic as="a" href="https://constructoragreenmartins.cl/" target="_blank" class="menu__item-link" rel="noopener noreferrer">Constructora</Generic>
				<img class="menu__item-img" src={Edile} alt="Impresa Edile" />
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>Constructora</span>
						<span>Constructora</span>
						<span>Constructora</span>
						<span>Constructora</span>
					</div>
				</div>
			</div>
			<div class="menu__item">
				<a href="https://assistenzatorino.netsons.org/" target="_blank" class="menu__item-link" rel="noopener noreferrer">Assistenza</a>
				<img class="menu__item-img" src={Assistenza} alt="Assistenza Sanitaria" />
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>Assistenza</span>
						<span>Assistenza</span>
						<span>Assistenza</span>
						<span>Assistenza</span>
					</div>
				</div>
			</div>
			<div class="menu__item">
				<a href="http://estebansanmartin.net/es/restaurant/" target="_blank" class="menu__item-link" rel="noopener noreferrer">Restaurant</a>
				<img class="menu__item-img" src={Restaurant} alt="Restaurant" />
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>Restaurant</span>
						<span>Restaurant</span>
						<span>Restaurant</span>
						<span>Restaurant</span>
					</div>
				</div>
			</div>
			<div class="menu__item">
				<a href="https://apprecipe.netlify.com" target="_blank" class="menu__item-link" rel="noopener noreferrer">Recipe APP</a>
				<img class="menu__item-img" src={Recipe} alt="Ditta Edile" />
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>Recipe APP</span>
						<span>Recipe APP</span>
						<span>Recipe APP</span>
						<span>Recipe APP</span>
					</div>
				</div>
			</div>
			<div class="menu__item">
				<a href="http://estebansanmartin.net/" target="_blank" class="menu__item-link" rel="noopener noreferrer">ReactJS</a>
				<img class="menu__item-img" src={Reactivo} alt="Portfolio Personale" />
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>ReactJS</span>
						<span>ReactJS</span>
						<span>ReactJS</span>
						<span>ReactJS</span>
					</div>
				</div>
			</div>
		</nav>
	</main>
</body> */ 