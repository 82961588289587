import React from "react"

import "rbx/index.css"
import { Icon, Hero, Title, Container, Column, Image, Level, Heading } from "rbx"
import Mapuche from "../../images/es2.png"

import Reveal from 'react-reveal/Reveal'
import "animate.css/animate.min.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faHtml5, faCss3, faJs, faNpm, faSass, faReact, faWordpress, faYarn, faNode, faBootstrap,  } from '@fortawesome/free-brands-svg-icons'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'

function ChiSono() {
    return(
        <div>
            <Reveal effect="fadeInUp" delay={2000}>
            <Hero size="fullheight-with-navbar">
                 <Hero.Body>
                    <Container textAlign="centered">
                        <Title className="title-head">salve</Title>
                            <Column.Group>
                                <Column>
                                    <Image className="image-about" src={Mapuche} />
                                 </Column>
                                 <Column style={{marginTop: '30px'}} size="three-fifths">
                                    <Title className="blockquote">
                                    <Icon className="quote">
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                     </Icon>
                                        Mi chiamo <i className="link">Esteban San Martin</i>, sono un <i>Frontend</i> con residenza a Torino. <br />Appassionato di design e delle nuove tecnologie digitali.
                                    <Icon className="icon">
                                        <FontAwesomeIcon icon={faQuoteRight} />
                                     </Icon>
                                    </Title>
                                 </Column>
                            </Column.Group>
                        <Title subtitle className="title-about" as="p">Il mio percorso proffesionale
                        </Title>
                        <Title className="text-about" as="h1" subtitle>
                        Dopo una laurea come <strong><i>Graphic Design</i></strong> alla Universidad Los Leones di Cile, ho iniziato subito a lavorare
                    come designer. Dopo un anno mi sono specializzato come <strong><i>Frontend</i></strong> nello sviluppo di siti web dinamici con CMS nella Universidad Catolica di Chile.
                    </Title>     
                        <Level className="brands" breakpoint="fullhd">
                            <Level.Item textAlign="centered">
                                <div>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faJs} />
                                    </Icon>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faReact} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faCss3} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faGithub} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faSass} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faWordpress} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faNode} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                            <Level.Item textAlign="centered">
                                <div>
                                <Heading>
                                    <Icon className="icon-large">
                                        <FontAwesomeIcon icon={faYarn} />
                                    </Icon>
                                </Heading>
                                </div>
                            </Level.Item>
                        </Level>
                        <Title className="text-about" as="h2" subtitle>
                        Come <strong><i>Grafico</i></strong> ho vasta esperienza con <i>Photoshop</i> e <i>Illustrator</i>, posso eseguire lavori di <strong><i>brand design</i></strong> (creare l'identità di un'azienda), Illustrazioni, 
                        prodotti editoriali, riviste, cataloghi, brochure.
                        </Title>


                        <Column.Group>
                                
                                 <Column style={{marginTop: '30px'}} size="three-fifths">
                                    <Title className="text-pc" as="h2" subtitle>
                                        Come <strong><i>Frontend</i></strong> dal punto di vista tecnico, offro competenza in <i>web design</i>, progettazzione di <strong><i>Siti Web</i></strong> ottimizzati per i motori di ricerca, accesibilità,
                                        usabilità, struttura dell'informazione, UI (user interface), UX (user experience), HTML <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faHtml5} />, CSS <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faCss3} />, SASS <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faSass} />, Javascript <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faJs} /> e frameworks (Bootstrap <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faBootstrap} />, Foundation, MaterialUI, Bulma). 
                                        Per i processi di dipendenze utilizzo NPM <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faNpm} /> o Yarn a seconda del progetto. 
                                        Con ReactJS <FontAwesomeIcon style={{color:"#FA7C91"}} icon={faReact} /> sviluppo Web Apps, utilizzando Componenti Funzionali, props, Hooks, e l'integrazione di sistemi e strumenti di terze parti como lo sono le API's,
                                        e per eseguire più velocemente il lavoro, e mantenere più pulito il JSX utilizzo Material-UI o BulmaCSS.
                                        Ho sviluppato diversi progetti, chatbox, clone di Twitter, Apps di ricerca, e altri ancora che potete vederli sulla mia pagina di <a href="https://github.com/estebansanmartin" rel="noopener noreferrer" target="_blank">Github</a> oppure potete
                                        vederli direttamente dalla pagina dei <a href="https://estebansanmartin.it/progetti" rel="noopener noreferrer" target="_blank">Progetti</a>.
                                    </Title>
                                 </Column>

                                 <Column className="aboutpc">
                                    <Image.Container>
                                        <Image  className="about-pc image-about" src="https://i.ibb.co/yYbbC3F/coder.jpg" alt="about frontend" />
                                    </Image.Container>
                                 </Column>

                            </Column.Group>

                        
                    </Container>
                </Hero.Body>
            </Hero>
            </Reveal>
        </div>
    )
}


export default ChiSono;