export const List = [
   {
      "id":"Studio",
      "avatar":"https://i.ibb.co/7kSrcJW/react.png",
      "name":"Studio",
      "fecha":"Agosto 2021",
      "url":"https://studioturin.netlify.app/",
      "description":"Sito web aziendale sviluppato con ReactJS, perfettamente visibili su tutti i dispositivi fissi e mobili.",
      "image":"https://i.ibb.co/GM8prsM/Studio-Architetti.png",
      "github":"https://github.com/estebansanmartin/architect",
   },
   {
      "id":"Moviepedia",
      "avatar":"https://i.ibb.co/fH6px1v/Javascript-736400-960-720.png",
      "name":"Moviepedia",
      "fecha":"marzo 2021",
      "url":"https://moviespedia.netlify.app/",
      "description":"App di ricerca sviluppata con Javascript utilizzando le API's di MovieDB in lingua italiana, per lo stile ho utilizzato CSS.",
      "image":"https://i.ibb.co/W5T5F7K/moviepedia.png",
      "github":"https://github.com/estebansanmartin/moviepedia",
   },
    {
       "id":"marvel",
       "avatar":"https://i.ibb.co/fH6px1v/Javascript-736400-960-720.png",
       "name":"Marvel Search",
       "fecha":"febraio 2021",
       "url":"https://github.com/estebansanmartin/Marvel",
       "description":"App di ricerca sviluppata con Javascript utilizzando le API's di Marvel, per lo stile e il Dark Mode ho utilizzato Material-UI.",
       "image":"https://i.ibb.co/02q2kXq/marvel.png",
       "github":"https://github.com/estebansanmartin/Marvel",
    },
    {
       "id":"recetas",
       "avatar":"https://i.ibb.co/7kSrcJW/react.png",
       "name":"Recipe Search",
       "fecha":"gennaio 2021",
       "url":"https://apprecipe.netlify.app/",
       "description":"App di ricerca sviluppata con ReactJS utilizzando un API di ricete, per lo stile ho utilizzato Material-UI.",
       "image":"https://i.ibb.co/y8PVSx6/Recipe-App.png",
       "github":"https://github.com/estebansanmartin/recipeapp",
    },
    {
       "id":"twitterclone",
       "avatar":"https://i.ibb.co/7kSrcJW/react.png",
       "name":"Clone Twitter",
       "fecha":"settembre 2020",
       "url":"https://clone-react.netlify.app/",
       "description":"Clone di Twitter sviluppato con ReactJS e le API's di Twitter, per lo stile ho utilizzato Material-UI, e per la parte back-end ho utilizatto Firebase.",
       "image":"https://i.ibb.co/840kKQk/twitter.png",
       "github":"https://github.com/estebansanmartin/cloneTwitter",
    },
    {
       "id":"messenger",
       "avatar":"https://i.ibb.co/7kSrcJW/react.png",
       "name":"Messenger",
       "fecha":"ottobre 2020",
       "url":"https://messaggi.netlify.app/",
       "description":"Chatbox con login Google sviluppato con ReactJS, per lo stile ho utilizzato Material-UI, e per la parte back-end ho utilizatto Firebase.",
       "image":"https://i.ibb.co/tcsgvf5/messenger.png",
       "github":"https://github.com/estebansanmartin/messenger",
    },
    {
     "id":"portfolioreact",
     "avatar":"https://i.ibb.co/7kSrcJW/react.png",
     "name":"Portfolio ReactJS",
     "fecha":"ottobre 2019",
     "url":"https://webdigital.netlify.app/",
     "description":"Portfolio sviluppato con ReactJS e una dipendenza di Canvas.",
     "image":"https://i.ibb.co/5n5rXpf/react.png",
     "github":"https://github.com/estebansanmartin/es",
    },
     {
      "id":"assistenza",
      "avatar":"https://i.ibb.co/2dP1sXn/wordpress.png",
      "name":"Camila Assistenza",
      "fecha":"marzo 2018",
      "url":"https://assistenzatorino.netsons.org/",
      "description":"Sito web aziendale per una ditta di Assistenza Anziani sviluppata con Wordpress.",
      "image":"https://i.ibb.co/q1zD4sv/assistenza1.png",
      "github":"https://github.com/estebansanmartin/",
      },
      {
        "id":"restaurant",
        "avatar":"https://i.ibb.co/4JmN5Wc/bootstrap.png",
        "name":"Restaurant",
        "fecha":"marzo 2017",
        "url":"https://www.estebansanmartin.it/restaurant/",
        "description":"Sito web di un Restaurant sviluppato con Bootstrap.",
        "image":"https://i.ibb.co/F0k3NxJ/restaurant1.png",
        "github":"https://github.com/estebansanmartin/",
       },
     {
      "id":"edilizia",
      "avatar":"https://i.ibb.co/4JmN5Wc/bootstrap.png",
      "name":"Edilizia",
      "fecha":"agosto 2017",
      "url":"https://estebansanmartin.it/edilizia",
      "description":"Sito Web di una Ditta Edile sviluppato con Bootstrap",
      "image":"https://i.ibb.co/4Rv9kY5/edilizia1.png",
      "github":"https://github.com/estebansanmartin",
     },
     {
      "id":"modaitalia",
      "avatar":"https://i.ibb.co/z2byBsj/materialize.png",
      "name":"Moda de italia",
      "fecha":"marzo 2016",
      "url":"https://estebansanmartin.it/modaitalia",
      "description":"E-commerce sviluppato con Materialize, per una tenda di abbigliamento in Cile.",
      "image":"https://i.ibb.co/v11KHHK/MODA-DE-ITALIA.png",
      "github":"https://github.com/estebansanmartin",
     },
     {
      "id":"firenze",
      "avatar":"https://i.ibb.co/807MkNK/logo.jpg",
      "name":"Firenze",
      "fecha":"gennaio 2016",
      "url":"https://estebansanmartin.it/firenze",
      "description":"Sito web sviluppato a tema con la città di Firenze",
      "image":"https://i.ibb.co/CH5TGyS/firenze.png",
      "github":"https://github.com/estebansanmartin",
     },
  ];